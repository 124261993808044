@charset 'UTF-8';
@import "bootsnav.scss";
@import "font-icons.min.scss";
@import "theme-vendors.min.scss";
@import "style.scss";
@import "responsive.scss";
@import "overrides.scss";

@keyframes shadow-pulse {
    0% { box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5); }
    100% { box-shadow: 0 0 0 35px rgba(0, 0, 0, 0); }
}

.radio_player_is_playing {
    animation: shadow-pulse 2s infinite;
}

.divascookies {
    position: relative;
    bottom: 0;
    width: 100%;
    background: rgba(200,16,46,0.97);
    color: #ffffff;
    display: none;
    z-index: 999999;
}

.divascookies > .divascookies-banner-container {
    position: relative;
    padding: 20px 20px 0px 20px;
    overflow: hidden;
    text-align: center;
}

.divascookies p.divascookies-banner-text {
    position: relative;
    line-height: 1.0em;
    font-size: 1.0em;
    display: inline-block;
    padding: 0 10px 0 0;
}

span.divascookies-policy-link a {
    color: #e5e5e5 !important;
    text-decoration: underline;
    white-space: nowrap;
}

.divascookies-banner-container > .divascookies-accept-button-container {
    display: inline-block;
    margin-top: 5px;
}

.divascookies-accept-button-container > .divascookies-accept-button-text {
    font-size: 1.0em;
    cursor: pointer;
    background: #e5e5e5;
    color: #1a1a1a;
    padding: 6px 9px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    transition: background-color 0.3s ease 0s;
}

.divascookies-accept-button-container > .divascookies-accept-button-text:hover {
    background: #e5e5e5;
}